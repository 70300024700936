import React from "react";
import { PageWrapper } from "~components/Core";
// import HeroSection from '~sections/project/Hero'
import Started from '../sections/GetStarted/Started'





export default function GetStarted() {
    return (
        <PageWrapper innerPage={true}>



            <Started />

        </PageWrapper>
    )
}

import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
// import SignUpForm from './style'
import Content from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Images } from '~data'
import { Link } from '~components'
// import SectionTitle from './Components/SectionTitle'
import { SuperTag } from '~components';
import { border } from 'polished'

import { style } from '@mui/system'
// import ReactTypingEffect from 'react-typing-effect';


export default function Started() {
    return (
        <Content>
            <Container
            //  className="position-static"
            >
                <Content.Inner>
                    <Row className="align-items-center justify-content-center position-static p-6">

                        <Col xs="12" className="  col-lg-6 col-md-9  ">

                            <Card
                                style={{ minHeight: '400px', height: 'auto' }} className="rounded-4 shadow"

                            >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '20px' }}>  <i className="fa fa-rocket rounded" style={{ backgroundColor: '#8a55df', color: '#ffffff', 'fontSize': '30px', padding: '4px' }} ></i>  Register your business</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                        We take care of your needs from start to finish. From incorporation, accounting, corporate secretary, insurance and setting up your business account.
                                    </Card.Text>

                                    <Content.Button variant="primary" >Register now</Content.Button>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" className=" col-lg-6 col-md-9   ">
                            <Card
                                style={{ minHeight: '400px', height: 'auto' }} className="rounded-4 shadow"
                            >
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body>
                                    <Card.Title style={{ color: '#8a55df', 'fontSize': '20px' }}> <i className="fa fa-hand-rock rounded" style={{ backgroundColor: '#8a55df', color: '#ffffff', 'fontSize': '30px', padding: '4px' }} ></i> Transfer to Sleek</Card.Title>
                                    <Card.Text style={{ color: '#000000', 'fontSize': '16px' }}>
                                        Already have an existing business? Transfer to Sleek to take advantage of our all-in-one platform and save yourself countless  hours.

                                    </Card.Text>
                                    <Content.Button variant="primary ">Transfer to Sleek</Content.Button>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Content.Inner>
            </Container>

        </Content >

    )
}
